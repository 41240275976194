import Home from 'components/page/home';
import { resolveFallbackGeoFilter } from 'lib/geolocation/resolveFallbackGeoFilter';
import { wrapper } from 'redux/appStore';
import fetchPageData from 'redux/actions';
import { fetchHomePage, fetchProductsInYourArea } from 'redux/homePage/homePage.thunks';
import { fetchTaxons, fetchTextBanner, TEXT_BANNERS } from 'redux/common/common.thunks';
import { fetchCities } from 'redux/geolocation/geolocation.thunks';
import { setGeoFilter } from 'redux/geolocation/geolocation.slice';
import { createGrowthBookFromData } from 'components/growthbook/growthbook.utils';
import { experiments } from 'components/experiment/experiment.registry';

export default Home;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { req, res } = context;
  res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=600');

  const geoFilter = resolveFallbackGeoFilter(req);

  const actions = [
    fetchTextBanner(TEXT_BANNERS.HOMEPAGE),
    fetchCities(),
    fetchTaxons(),
    fetchHomePage(),
  ];

  const growthBook = createGrowthBookFromData(req.growthBookData);

  if (geoFilter.query && !growthBook.isOn(experiments.AVDD_9185)) {
    actions.push(fetchProductsInYourArea(geoFilter.query));
  }
  store.dispatch(setGeoFilter(geoFilter));
  growthBook.destroy();

  return fetchPageData(store, actions);
});
